import React from 'react';
import { graphql } from 'gatsby';
import DesktopLayout from '../layouts/Desktop';

const MyFiles = ({ data }) => {
  return (
    <DesktopLayout>
      <div>
        <h1>My Site's files</h1>
        <table>
          <thead>
            <tr>
              <th>Relative Path</th>
              <th>Size</th>
              <th>Extension</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            {data.allFile.edges.map(({ node: file }, index) => (
              <tr key={index}>
                <td>{file.relativePath}</td>
                <td>{file.prettySize}</td>
                <td>{file.extension}</td>
                <td>{file.birthTime}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </DesktopLayout>
  );
};

export const query = graphql`
  {
    allFile(sort: { fields: [birthTime], order: DESC }) {
      edges {
        node {
          relativePath
          birthTime(fromNow: true)
          prettySize
          extension
        }
      }
    }
  }
`;

export default MyFiles;
